<template>
	<el-dialog
			top="0" class="commonDia updateMem" :close-on-click-modal="false"
			:visible.sync="dialogUser" :show-close="false"
			width="600px" :close-on-press-escape="false">
		<template #title>
			<h1 class="tit">{{ statusText }}用户</h1>
		</template>
		<el-form label-position="top" ref="form" class="rowsInfo" label-suffix="：" label-width="80px" :model="userForm">
			<div class="flex">
				<el-form-item label="姓名">
					<el-input v-model="userForm.name" clearable placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item label="角色">
					<el-select multiple collapse-tags  v-model="userForm.roles" :placeholder="roleName">
						<el-option :label="item.roleName" :key="ind" v-for="(item,ind) in roleList" :value="item.id"></el-option>
					</el-select>
				</el-form-item>
			</div>
			<div class="flex">
				<el-form-item label="账号">
					<el-input v-model="userForm.account" clearable placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="密码" v-if="statusText=='新增'">
					<el-input v-model="userForm.password" type="password" clearable placeholder="请输入密码"></el-input>
				</el-form-item>
			</div>
			<div class="flex">
				<el-form-item label="手机号码">
					<el-input v-model="userForm.tel" clearable placeholder="请输入手机号码"></el-input>
				</el-form-item>
				<el-form-item label="部门">
					<el-input v-model="userForm.depart" clearable placeholder="请输入部门"></el-input>
				</el-form-item>
			</div>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="resetForm">取 消</el-button>
			<el-button type="primary" @click="userButton('form')">确 定</el-button>
		</div>
	</el-dialog>

</template>

<script>
import { addAuthUser, updateAuthUser } from "@/api/api";

export default {
	name: "userAdd",
	props: {
		showEditUser: {
			type: Boolean,
			default: false
		},
		state: {
			type: String
		},
		roleName: {
			type: String
		},
		userInfo: {
			type: Object
		},
		roleList:{
			type: Array
		}
	},
	data() {
		return {
			dialogUser: this.showEditUser,
			statusText: this.state,
			userForm: {},
			parkLotSel: [{
				val: "1",
				label: "管理员"
			}, {
				val: "2",
				label: "编辑"
			}]
		};
	},
	watch: {
		showEditUser: {
			handler(newval) {
				this.dialogUser = newval;
			}
		},
		state: {
			handler(newval) {
				this.statusText = newval;
			}
		},
		userInfo: {
			handler(val) {
				if (val) {
					this.userForm = JSON.parse(JSON.stringify(this.userInfo));
				} else {
					this.userForm = {
						name: "",
						roleNames: "",
						tel: "",
						depart: ""
					};
				}
			},
			deep: true
		}
	},
	mounted() {
	},
	methods: {
		resetForm() {
			this.$emit("openUser", { "flg": false });
		},
		userButton(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					if (this.statusText === "新增") {
						addAuthUser(JSON.stringify(this.userForm)).then((res) => {
							if (res.data.code == 200){
								this.$message.success("新增成功");
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(() => {
							this.$message.error("新增失败");
						});
					} else {
						updateAuthUser(JSON.stringify(this.userForm)).then((res) => {
							this.$message.success(res.data.msg);
						}).catch(() => {
							this.$message.error("修改失败");
						});
					}
					setTimeout(() => {
						this.$emit("openUser", { "flg": false });
					}, 500);
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/table";
</style>
